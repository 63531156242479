import { eventChannel, EventChannel } from 'redux-saga';
import { take, put, call, fork, cancel } from 'redux-saga/effects';
import * as types from './types';
import * as actions from './actions';

// WebSocket connection saga
function* connectWebSocketSaga() {
  const wsUrl = "ws://localhost:8002/ws/chatapp/?token=0e9b9e0a7794582ac06f43b939e982bc23d2c824";
  const socket = new WebSocket(wsUrl);

  const channel: EventChannel<string> = yield call(createWebSocketChannel, socket);

  yield fork(readWebSocketMessages, channel);

  yield put(actions.connectedWebSocket());

  while (true) {
    const { payload } = yield take(types.WEBSOCKET_DISCONNECT);
    if (payload) {
      yield call(closeWebSocket, socket);
      return;
    }
  }
}

// Saga to create a WebSocket channel
function createWebSocketChannel(socket: WebSocket): EventChannel<string>  {
  return eventChannel<string>((emit) => {
    const onMessage = (event: MessageEvent) => emit(event.data);
    // const onError = (error: Event) => emit(
    //   actions.websocketError(new Error((error as ErrorEvent).message))
    //   );
    const onError = (error: Event) => emit((error as ErrorEvent).message);
    
    // const onClose = () => emit(actions.disconnectWebSocket());
    const onClose = () => emit('End');

    socket.addEventListener('message', onMessage);
    socket.addEventListener('error', onError);
    socket.addEventListener('close', onClose);

    return () => {
      socket.removeEventListener('message', onMessage);
      socket.removeEventListener('error', onError);
      socket.removeEventListener('close', onClose);
    };
  });
}

// Saga to read WebSocket messages
function* readWebSocketMessages(channel: ReturnType<typeof createWebSocketChannel>) {
  while (true) {
    const message: string = yield take(channel);
    yield put(actions.websocketMessageReceived(message));
  }
}

// Saga to close WebSocket connection
function closeWebSocket(socket: WebSocket) {
  socket.close();
}

// Root saga for WebSocket
function* websocketSaga() {
  while (true) {
    yield take(types.WEBSOCKET_CONNECT);
    const task: Promise<any> = yield fork(connectWebSocketSaga);
    // const task: Task<T> = yield call(connectWebSocketSaga);
    yield take(types.WEBSOCKET_DISCONNECT);
    // yield cancel(task: Promise<any>);
  }
}

export default websocketSaga;