import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import { eventChannel, EventChannel } from 'redux-saga';
// import ActionPattern from "redux-saga"
// import { ActionPattern, Buffer, Channel, GuardPredicate, Pattern, Task, Effect } from '@redux-saga/types'
import { ActionPattern } from '@redux-saga/types'
import { Action } from 'redux'

// Redux State
import { BookmarksActionTypes } from "./types";

// actions
import {
  bookmarksApiResponseSuccess,
  bookmarksApiResponseError,
} from "./actions";

// api
import {
  getBookmarks as getBookmarksApi,
  updateBookmark as updateBookmarkApi,
  deleteBookmark as deleteBookmarkApi,
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getBookmarks() {
  try {
    const response: Promise<any> = yield call(getBookmarksApi);
    yield put(
      bookmarksApiResponseSuccess(BookmarksActionTypes.GET_BOOKMARKS, response)
    );
  } catch (error: any) {
    yield put(
      bookmarksApiResponseError(BookmarksActionTypes.GET_BOOKMARKS, error)
    );
  }
}

function* updateBookmark({ payload: { id, data } }: any) {
  try {
    const response: Promise<any> = yield call(updateBookmarkApi, id, data);
    yield put(
      bookmarksApiResponseSuccess(
        BookmarksActionTypes.UPDATE_BOOKMARK,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      bookmarksApiResponseError(BookmarksActionTypes.UPDATE_BOOKMARK, error)
    );
  }
}

function* deleteBookmark({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(deleteBookmarkApi, id);
    yield put(
      bookmarksApiResponseSuccess(
        BookmarksActionTypes.DELETE_BOOKMARK,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      bookmarksApiResponseError(BookmarksActionTypes.DELETE_BOOKMARK, error)
    );
  }
}

// //===================================================

// function initWebsocket() {  
//   return eventChannel(emitter => {    
//         // const newSocket = new WebSocket('ws://localhost:8002/ws/chatapp/?token=0e9b9e0a7794582ac06f43b939e982bc23d2c824');
//     const wsUrl = "ws://localhost:8002/ws/chatapp/?token=0e9b9e0a7794582ac06f43b939e982bc23d2c824";
//     const ws = new WebSocket(wsUrl )  ;  
//     ws.onopen = () => {
//       console.log('opening...');
//       ws.send('hello server');
//     }    
//     ws.onerror = (error: any) => {
//       console.log('WebSocket error ' + error);
//       console.dir(error);
//     }    
//     ws.onmessage = (e) => {
//       let msg = null;
//       try {
//         msg = JSON.parse(e.data);
//       } catch(e: any) {
//         console.error(`Error parsing : ${e.data}`);
//       }
//       // if (msg) {
//       //   const { payload: book } = msg        
//       //   const channel = msg.channel
//       //   switch (channel) {
//       //     case 'ADD_BOOK':
//       //       return emitter({ type: ADD_BOOK, book })
//       //     case 'REMOVE_BOOK':
//       //       return emitter({ type: REMOVE_BOOK, book })
//       //     default:
//       //       // nothing to do
//       //   }
//       // }
//     }    // unsubscribe function
//     return () => {
//       console.log('Socket off');
//     }
//   })
// }

// export function* watchWebsockets() {
//     const channel: ActionPattern<Action<any>>  = yield call(initWebsocket);
//     while (true) {
//     const action: Action<any>  = yield take(channel);
//     yield put(action);
//   }
// }

// //=====================================================


export function* watchGetCalls() {
  yield takeEvery(BookmarksActionTypes.GET_BOOKMARKS, getBookmarks);
}

export function* watchUpdateBookmark() {
  yield takeEvery(BookmarksActionTypes.UPDATE_BOOKMARK, updateBookmark);
}
export function* watchDeleteBookmark() {
  yield takeEvery(BookmarksActionTypes.DELETE_BOOKMARK, deleteBookmark);
}
function* bookmarksSaga() {
  yield all([
    fork(watchGetCalls),
    fork(watchUpdateBookmark),
    fork(watchDeleteBookmark),
    // fork(watchWebsockets),

  ]);
}

export default bookmarksSaga;
