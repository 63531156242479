import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getStaffs = (filters?: object) => {
  return api.get(url.GET_STAFFS, filters);
};

const inviteStaff = (data: object) => {
  return api.create(url.INVITE_STAFF, data);
};
export { getStaffs, inviteStaff };
