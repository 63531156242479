import * as types from './types';

// Interface for WebSocket state
interface WebSocketState {
  isConnected: boolean;
  message: string | null;
  error: Error | null;
}

// Initial state for WebSocket
const initialState: WebSocketState = {
  isConnected: false,
  message: null,
  error: null,
};

// WebSocket reducer
const websocketReducer = (state = initialState, action: { type: string; payload: any }): WebSocketState => {
  switch (action.type) {
    case types.WEBSOCKET_CONNECTED:
      return {
        ...state,
        isConnected: true,
        error: null,
      };
    case types.WEBSOCKET_DISCONNECT:
      return {
        ...state,
        isConnected: false,
      };
    case types.WEBSOCKET_MESSAGE_RECEIVED:
      return {
        ...state,
        message: action.payload,
      };
    case types.WEBSOCKET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default websocketReducer;
