import axios from "axios";
import config from "../config";

// default
axios.defaults.baseURL = config.API_URL;
// const current = window.location.origin;
// const current = location.host.includes("localhost") ? config.API_URL : window.location.origin;
// const currentUrl  = window.location.host.includes("localhost")
//   ? config.API_URL
//   : window.location.origin + "/middle/";
// console.log('current domain', currentUrl );
console.log('ws url', config.WS_URL);
// content type
axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";




//==my code ===
axios.interceptors.request.use(
  (config) => {
    // console.log('==intercepted=');
    const user = localStorage.getItem("authUser");
    if (user) {
      const authUser = JSON.parse(user);
      // console.log('==inrt==', authUser.access);
      config.headers.Authorization = `Bearer ${authUser.access}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//==my code ==




// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        // console.log('+error+', error.status, error.message, error.data);
        // message = error.response.data || error.message || error;
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}) => {
    // console.log('==headers==',axios.defaults.headers);

    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data?: {}) => {
    // console.log('==headers==',axios.defaults.headers);
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data?: {}) => {
    // console.log('==headers==',axios.defaults.headers);
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}) => {
    console.log('==headers==',axios.defaults.headers);
    return axios.delete(url, { ...config });
  };

  /*
   file upload update method
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.put(url, formData, config);
  };

  /*
   file upload post method
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    data.image && data.image.forEach((img: any) => {
      // console.log(`Image ID: ${img.id}`);
      // console.log('Download Link:', img.downloadLink);
      formData.append(`images`, img.downloadLink);

    });

    data.attachments && data.attachments.forEach((img: any) => {
      // console.log(`Image ID: ${img.id}`);
      // console.log('Download L  ;lmmmmmmlkjljlkljlkjkljljlj.,.m.mink:', img.downloadLink);
      formData.append(`attachments`, img.downloadLink);

    });

    // for (const image in data['image']){
    //   console.log('=image=', image);
    // }
    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
