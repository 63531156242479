// types
import { StaffsActionTypes, StaffsState } from "./types";

export const INIT_STATE: StaffsState = {
  staffs: [],
};

const Staffs = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case StaffsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case StaffsActionTypes.GET_STAFFS:
          return {
            ...state,
            staffs: action.payload.data,
            isStaffsFetched: true,
            getStaffsLoading: false,
          };
        case StaffsActionTypes.INVITE_STAFF:
          return {
            ...state,
            isStaffInvited: true,
          };
        default:
          return { ...state };
      }

    case StaffsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case StaffsActionTypes.GET_STAFFS:
          return {
            ...state,
            isStaffsFetched: false,
            getStaffsLoading: false,
          };
        case StaffsActionTypes.INVITE_STAFF:
          return {
            ...state,
            isStaffInvited: false,
          };
        default:
          return { ...state };
      }

    case StaffsActionTypes.GET_STAFFS: {
      return {
        ...state,
        getStaffsLoading: true,
        isStaffsFetched: false,
      };
    }

    case StaffsActionTypes.INVITE_STAFF:
      return {
        ...state,
        isStaffInvited: false,
      };
    case StaffsActionTypes.RESET_STAFFS:
      const flag = action.payload.flag;
      const value = action.payload.value;
      let flags: any = {};
      flags[flag] = value;
      return {
        ...state,
        ...flags,
      };
    default:
      return { ...state };
  }
};

export default Staffs;
