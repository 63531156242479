// import React, { useState, useEffect } from 'react';
// interface TimerProps {
//   expiryTimestamp: Date; // Define the type of expiryTimestamp prop
// }

// interface TimeLeft {
//   days?: number;
//   hours?: number;
//   minutes?: number;
//   seconds?: number;
// }

// const CountdownTimer1 = ({ expiryTimestamp }: TimerProps)  => {
//   const calculateTimeLeft = () => {
//     const now = new Date();
//     // const difference = expiryTimestamp - now;
//     const difference = expiryTimestamp.getTime() - now.getTime();
//     let timeLeft = {};

//     if (difference > 0) {
//       timeLeft = {
//         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//         minutes: Math.floor((difference / 1000 / 60) % 60),
//         seconds: Math.floor((difference / 1000) % 60),
//       };
//     }

//     return timeLeft;
//   };

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [expiryTimestamp]);

//   const timerComponents = [];

//   Object.keys(timeLeft).forEach((interval) => {
//     if (!timeLeft[interval]) {
//       return;
//     }

//     timerComponents.push(
//       <span key={interval}>
//         {timeLeft[interval]} {interval}{" "}
//       </span>
//     );
//   });

//   return (
//     <div>
//       {timerComponents.length ? timerComponents : <span>Time's up!</span>}
//     </div>
//   );
// };

// export default CountdownTimer1;


import React, { useState, useEffect } from 'react';
import styles from '../assets/css/timer.module.css';

interface TimerProps {
  expiryTimestamp: Date; // Date object representing the expiration time
}

// const CountDownTimer: React.FC<TimerProps> = ({ expiryTimestamp }) => {
const CountDownTimer1 = ({ expiryTimestamp }: TimerProps)  => {	
  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(expiryTimestamp)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(calculateRemainingTime(expiryTimestamp));
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [expiryTimestamp]);

  if (remainingTime <= 0) {
    return <div>Time Expired!</div>;
  }

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  // return (
  //   <div>
  //     {days > 0 && <span>{days}d</span>}
  //     {hours > 0 && <span>{hours.toString().padStart(2, '0')}h</span>}
  //     {minutes > 0 && <span>{minutes.toString().padStart(2, '0')}m</span>}
  //     {seconds > 0 && <span>{seconds.toString().padStart(2, '0')}s</span>}
  //   </div>
  // );

  return (
    <>
      {/*<h1>react-timer-hook</h1>*/}
      
{/*===============================================================================================*/}

    <div className={`${styles.bmqfAw} justify-content-center`}>    
        <div className={styles.kwzonl}>
            <span className={styles.ggQXgx}>DAYS</span>
            <div className={styles.fCzZSM}>
                <span className={styles.fCuaIg}>{ Math.floor(days/10) }</span>
                <span className={styles.fCuaIg}>{ days % 10 }</span>
            </div>
        </div>
        <span className={styles.iglHij}>
            <span className={styles.cdCtrd}></span>
            <span className={styles.cdCtrd}></span>
        </span>
        <div className={styles.kwzonl}>
            <span className={styles.ggQXgx}>HOURS</span>
            <div className={styles.fCzZSM}>
                <span className={styles.fCuaIg}>{ Math.floor(hours/10) }</span>
                <span className={styles.fCuaIg}>{ hours % 10 }</span>
            </div>
        </div>
        <span className={styles.iglHij}>
            <span className={styles.cdCtrd}></span>
            <span className={styles.cdCtrd}></span>
        </span>
        <div className={styles.kwzonl}>
            <span className={styles.ggQXgx}>MINUTES</span>
            <div className={styles.fCzZSM}>
                <span className={styles.fCuaIg}>{ Math.floor(minutes/10) }</span>
                <span className={styles.fCuaIg}>{ minutes % 10 }</span>
            </div>
        </div>
        <span className={styles.iglHij}>
            <span className={styles.cdCtrd}></span>
            <span className={styles.cdCtrd}></span>
        </span>
        <div className={styles.kwzonl}>
            <span className={styles.ggQXgx}>SECONDS</span>
            <div className={styles.fCzZSM}>
                <span className={styles.fCuaIg}>{ Math.floor(seconds/10) }</span>
                <span className={styles.fCuaIg}>{ seconds % 10 }</span>
            </div>
        </div>
    </div>

{/*===============================================================================================*/}




    </>
  );

};

function calculateRemainingTime(expiryTimestamp: Date) {
  const now = new Date();
  const diffInMilliseconds = expiryTimestamp.getTime() - now.getTime();
  return diffInMilliseconds > 0 ? diffInMilliseconds : 0; // Ensure non-negative value
}

export default CountDownTimer1;
