import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { StaffsActionTypes } from "./types";
import {
  staffsApiResponseSuccess,
  staffsApiResponseError,
} from "./actions";

// api
import {
  getStaffs as getStaffsApi,
  inviteStaff as inviteStaffApi,
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

function* getStaffs({ payload: filters }: any) {
  try {
    const response: Promise<any> = yield call(getStaffsApi, filters);
    yield put(
      staffsApiResponseSuccess(StaffsActionTypes.GET_STAFFS, response)
    );
  } catch (error: any) {
    yield put(
      staffsApiResponseError(StaffsActionTypes.GET_STAFFS, error)
    );
  }
}

function* inviteStaff({ payload: newPassword }: any) {
  try {
    const response: Promise<any> = yield call(inviteStaffApi, newPassword);
    yield put(
      staffsApiResponseSuccess(StaffsActionTypes.INVITE_STAFF, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      staffsApiResponseError(StaffsActionTypes.INVITE_STAFF, error)
    );
  }
}

export function* watchGetStaffs() {
  yield takeEvery(StaffsActionTypes.GET_STAFFS, getStaffs);
}

export function* watchInviteStaff() {
  yield takeEvery(StaffsActionTypes.INVITE_STAFF, inviteStaff);
}

function* staffsSaga() {
  yield all([fork(watchGetStaffs), fork(watchInviteStaff)]);
}

export default staffsSaga;
