export enum StaffsActionTypes {
  API_RESPONSE_SUCCESS = "@@staffs/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@staffs/API_RESPONSE_ERROR",

  GET_STAFFS = "@@staffs/GET_STAFFS",
  RESET_STAFFS = "@@staffs/RESET_STAFFS",
  INVITE_STAFF = "@@staffs/INVITE_STAFF",
}
export interface StaffsState {
  staffs: Array<any>;
}
