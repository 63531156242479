import { StaffsActionTypes } from "./types";

// common success
export const staffsApiResponseSuccess = (actionType: string, data: any) => ({
  type: StaffsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const staffsApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: StaffsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getStaffs = (filters?: object) => ({
  type: StaffsActionTypes.GET_STAFFS,
  payload: filters,
});

export const inviteStaff = (data: any) => ({
  type: StaffsActionTypes.INVITE_STAFF,
  payload: data,
});

export const resetStaffs = (flag: string, value: any) => ({
  type: StaffsActionTypes.RESET_STAFFS,
  payload: { flag, value },
});
