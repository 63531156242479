import React from "react";

interface StatusProps {
  about: string;
  stage: string;
  queue: string;
}
const Status = ({ about, queue, stage }: StatusProps) => {
  return (
    <div className="text-muted pt-4">

{/*
      <h5 className="font-size-11 text-uppercase">Status :</h5>
      <p className="mb-4">{about ? about : "-"}</p>

*/}
      <h5 className="font-size-11 text-uppercase">Queue :</h5>
      <p className="mb-4">{queue ? queue : "-"}</p> 
      <h5 className="font-size-11 text-uppercase">Stage :</h5>
      <p className="mb-4">{stage ? stage : "-"}</p>           
    </div>
  );
};

export default Status;
