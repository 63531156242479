import { toast } from "react-toastify";
import avatar1 from "../assets/images/users/avatar-1.jpg";
import beepSound from '../assets/audio/beep.mp3';
// import avatar1 from "../../assets/audio/beep.mp3";
const showSuccessNotification = (message: string) => {
  
  const title: string = "Exsyt Notification";
  // const body: string = message;

  // Play beep sound
  // const beep = new Audio("../assets/audio/beep.mp3");
  const beep = new Audio(beepSound);
  beep.play();
  console.log('avatar', beepSound);

  // Check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notifications.");
  } else if (Notification.permission === "granted") {
    // If notifications are allowed, display the notification
    const notification = new Notification(title, { body: message });
  } else if (Notification.permission !== "denied") {
    // Otherwise, ask the user for permission
    Notification.requestPermission().then(function(permission) {
      if (permission === "granted") {
        const notification = new Notification(title, { body: message });
      }
    });
  }




  toast.success(message);
};


const showMessageNotification = (message: string) => {
  
  const title: string = "Exsyt Notification";
  // const body: string = message;

  // Play beep sound
  // const beep = new Audio("../assets/audio/beep.mp3");
  const beep = new Audio(beepSound);
  beep.play();
  console.log('avatar', beepSound);

  // Check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notifications.");
  } else if (Notification.permission === "granted") {
    // If notifications are allowed, display the notification
    const notification = new Notification(title, { body: message });
  } else if (Notification.permission !== "denied") {
    // Otherwise, ask the user for permission
    Notification.requestPermission().then(function(permission) {
      if (permission === "granted") {
        const notification = new Notification(title, { body: message });
      }
    });
  }




  toast.success(message);
};

const showErrorNotification = (error: string) => {
  toast.error(error);
};

const showActionNotification = (params: unknown) => {
  // toast.success(message);
  const { success, message } = params as { success: boolean; message: string };
    if (success) {
      showSuccessNotification(message);
    }
    else {
      showErrorNotification(message);
    } 
  console.log('==notification==', message)
};




export { showSuccessNotification, showErrorNotification, showActionNotification, showMessageNotification };
