import * as types from './types';

// Action creators for WebSocket actions
export const connectWebSocket = () => ({ 
  type: types.WEBSOCKET_CONNECT 
});

export const connectedWebSocket = () => ({ 
  type: types.WEBSOCKET_CONNECTED 
});

export const disconnectWebSocket = () => ({ 
  type: types.WEBSOCKET_DISCONNECT 
});

export const websocketMessageReceived = (message: string) => ({ 
  type: types.WEBSOCKET_MESSAGE_RECEIVED, 
  payload: message 
});

export const websocketError = (error: Error) => ({ 
  // type: types.WEBSOCKET_ERROR, 
  type: '@@redux-saga/CHANNEL_END',
  payload: error 
});
